import React, { Suspense, useEffect, useState, lazy } from "react";

// Libraries
import { Routes, Route, useLocation } from "react-router-dom";
import retina from "retinajs";
import { AnimatePresence } from "framer-motion";

// Context
import GlobalContext from "./Context/Context";

// Components
import ScrollToTopButton from "./Components/ScrollToTop"

// Home
const ConsultingPage = lazy(() => import("./Pages/Home/Consulting"))

// Pages
const TeamPage = lazy(() => import("./Pages/Elements/Team"))
const TabPage = lazy(() => import("./Pages/Elements/Tab"))
const GoogleMapPage = lazy(() => import("./Pages/Elements/GoogleMap"))
const IconWithTextPage = lazy(() => import("./Pages/Elements/IconWithText"))
const SocialIconsPage = lazy(() => import("./Pages/Elements/SocialIcons"))

// Header
const MobileMenuClassicPage = lazy(() => import("./Pages/Header/MobileMenu/MobileMenuClassicPage"))

// About Pages
const AboutUsPage = lazy(() => import("./Pages/About/AboutUsPage"))
const ExoAcademy = lazy(() => import("./Pages/Academy/ExoAcademy"))
const CommunitySupport = lazy(() => import("./Pages/Sponsorship/CommunitySupport"))
const ContactUsClassicPage = lazy(() => import("./Pages/Contact/ContactUsClassicPage"))
// const ContactUsModernPage = lazy(() => import("./Pages/Contact/ContactUsModernPage"))
const Privacy = lazy(() => import("./Pages/Privacy"))
 
const OurTeamPage = lazy(() => import("./Pages/AdditionalPages/OurTeamPage"))
const Footer = lazy(() => import("./Pages/Footer"))

function App() {
  const [headerHeight, setHeaderHeight] = useState(0);
  const [footerHeight, setFooterHeight] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [customModal, setCustomModal] = useState({
    el: null,
    isOpen: false
  })
  const location = useLocation();

  // RetinaJS
  useEffect(() => {
    window.addEventListener('load', retina(document.querySelectorAll('img')));
  }, [])

  useEffect(() => {
    setTimeout(() => {
      import("./Functions/Utilities").then(module => {
        module.SetHeaderMenuPos()
        module.setDocumentFullHeight()
      })
    }, 1000);
  }, [location])

  useEffect(() => {
    if (isModalOpen === true) {
      document.querySelector("body").classList.add("overflow-hidden");
    } else {
      document.querySelector("body").classList.remove("overflow-hidden");
    }
  }, [isModalOpen]);

  // Get the current location and set the window to top
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant",
    });
    setFooterHeight(0);
    setCustomModal({
      ...customModal,
      el: null,
      isOpen: false
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <GlobalContext.Provider
      value={{
        headerHeight,
        setHeaderHeight,
        footerHeight,
        setFooterHeight,
        isModalOpen,
        setIsModalOpen,
        customModal,
        setCustomModal,
      }}
    >
      <div className="App" style={{ "--header-height": `${headerHeight}px` }}>
        {
          <main style={{ marginTop: headerHeight, marginBottom: footerHeight }}>
            <ScrollToTopButton />
            <AnimatePresence exitBeforeEnter>
              <Suspense fallback={<></>}>
                <Routes>
                  {/* <Route path="/" element={<LandingPage />} /> */}

                  {/* Headers */}
                  <Route path="headers">
                    <Route path="mobile-menu-classic" element={<MobileMenuClassicPage />} /> 
                  </Route>
                  {/* Footers */}
                  <Route path="footers" element={<Footer style={{ "--base-color": "#0038e3" }} />} >
                  </Route>

                  <Route path="/" element={<ConsultingPage style={{ "--base-color": "#bf8c4c" }} />} />
                    <Route path="tab" element={<TabPage />} />
                    <Route path="google-map" element={<GoogleMapPage />} />
                    <Route path="icon-with-text" element={<IconWithTextPage />} />
                    <Route path="team" element={<TeamPage />} />
                   
                    <Route path="social-icons" element={<SocialIconsPage />} />

                  {/*About Pages */}
                  <Route path="/about-us" element={<AboutUsPage style={{ "--base-color": "#0038e3" }} />} />
                   {/*About Pages */}
                   <Route path="/exo-academy" element={<ExoAcademy style={{ "--base-color": "#0038e3" }} />} />
                  

                  {/* Contact Pages */}
                  <Route path="/contact-us" element={<ContactUsClassicPage style={{ "--base-color": "#0038e3" }} />} />
                  <Route path="/community-support" element={<CommunitySupport style={{ "--base-color": "#0038e3" }} />} />

                  {/* Additional Pages */}
                  <Route path="/job-openings" element={<OurTeamPage style={{ "--base-color": "#0038e3" }} />} />
                  <Route path="/privacy" element={<Privacy style={{ "--base-color": "#0038e3" }} />} />

                 
                </Routes>
              </Suspense>
            </AnimatePresence>
          </main>
        }
      </div>
    </GlobalContext.Provider>
  )
}

export default App;